import { Box } from '@mantine/core';

export interface LogoProps {
  size: number;
  color?: string;
  sx?: any;
}

export const Logo = (props: LogoProps): JSX.Element => {
  return (
    <Box w={props.size} h={props.size} sx={props.sx}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <title>Marti Health Logo</title>
        <g fill={props.color || '#77DCCA'}>
          <path d="M448.5,212.5c-12.1-11.9-26.7-19.7-42.1-24.2C436.5,113.7,314.3,42.4,255.9,6 C197.6,42.4,75.3,113.7,105.5,188.2c-15.3,4.5-29.9,12.3-42,24.2C-30.9,319.4,187.3,453.7,256,506 C324.7,453.8,542.9,319.4,448.5,212.5z M312.5,362c-15.1,15-35.2,23.3-56.6,23.3c-21.4,0-41.5-8.3-56.6-23.3 c-31.2-30.9-31.2-81.3,0-112.3c0,0,29.1-28.5,30.9-30.2l-15-14.8c-22.4-22.3-22.4-58.5,0-80.8c10.9-10.8,25.4-16.7,40.8-16.7 c15.4,0,29.9,5.9,40.8,16.7c22.4,22.3,22.4,58.5,0,80.8l-15,14.8c1.8,1.8,30.9,30.2,30.9,30.2C343.8,280.6,343.8,331,312.5,362z" />
        </g>
      </svg>
    </Box>
  );
};
